// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
window.$ = window.jQuery = $;
import ahoy from "ahoy.js";

ahoy.trackAll();

// $(document).on('turbolinks:load', function () {
//
// });



$( document ).ready(function() {
  $( ".intro-wrapper" ).animate({
    opacity: 1,
    height: "toggle"
  }, 2000, function() {
    console.log('Animation complete.')
  });



  $("a[href^='tel:']").click(function() {
    ahoy.track("phone", {phone: $(this).attr('href')});
  });

});

// import "controllers"
